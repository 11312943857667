import {type PropsWithChildren} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'

export const aspectRatioVariants = tv({
  slots: {
    assetWrapper: 'w-full overflow-hidden rounded-xl',
    wrapper: 'w-full',
  },
  variants: {
    aspectRatio: {
      '16:5': {assetWrapper: 'aspect-h-5 aspect-w-16'},
      '16:9': {assetWrapper: 'aspect-h-9 aspect-w-16'},
      '4:5': {assetWrapper: 'aspect-h-5 aspect-w-4'},
      '3:2': {assetWrapper: 'aspect-h-2 aspect-w-3'},
      '1:1': {assetWrapper: 'aspect-h-1 aspect-w-1'},
      '2:1': {assetWrapper: 'aspect-h-1 aspect-w-2'},
    },
    topSpacing: {
      s0: {wrapper: 'pt-s0'},
      s6: {wrapper: 'pt-s6'},
      s9: {wrapper: 'pt-s9'},
      s12: {wrapper: 'pt-s12'},
    },
  },
})

export type HgAspectRatioVariants = VariantProps<typeof aspectRatioVariants>
export type AspectRatio = keyof typeof aspectRatioVariants.variants.aspectRatio

export type HgAspectRatioProps = PropsWithChildren<
  {
    classNames?: {
      assetWrapper?: string
      wrapper?: string
    }
  } & HgAspectRatioVariants
>

const HgAspectRatio = ({
  children,
  aspectRatio,
  topSpacing,
  classNames,
}: HgAspectRatioProps) => {
  const {wrapper, assetWrapper} = aspectRatioVariants({
    aspectRatio,
    topSpacing,
  })
  return (
    <div className={wrapper({class: classNames?.wrapper})}>
      <div className={assetWrapper({class: classNames?.assetWrapper})}>
        {children}
      </div>
    </div>
  )
}

export default HgAspectRatio
