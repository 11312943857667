'use client'

import {usePathname, useRouter, useSearchParams} from 'next/navigation'
import {useEffect, useState} from 'react'
import {tv} from 'tailwind-variants'
import {isValidSortOrder, type SortType} from '~/app/_utils/isValidSortOrder'
import {
  HgIcon,
  HgSortButton,
  HgToolTip,
  type HgIconType,
} from '~/design-system/hg/components'
import useQueryStringGenerator from '~/design-system/hooks/useQueryStringGenerator'
import {cn} from '~/design-system/utils'
import {type SortOrder} from '../HgSortButton'

export type HgIndexTableTitleCellContentProps = {
  columnName: string
  sortProperties?: {
    isSortable: boolean
    sortType: SortType
  }
  title?: React.ReactNode
  body?: React.ReactNode
  hint?: React.ReactNode
  iconType?: HgIconType
  className?: string
}

const sortButtonVariant = tv({
  base: 'static before:absolute before:left-0 before:top-0 before:z-0 before:block before:h-full before:w-full before:cursor-pointer',
})

const TooltipIcon = <HgIcon size="small" iconType="info-circle" />

export const HgIndexTableTitleCell = ({
  title,
  body,
  hint,
  iconType,
  className,
  columnName,
  sortProperties,
}: HgIndexTableTitleCellContentProps) => {
  const [currentSortOrder, setCurrentSortOrder] = useState<SortOrder>('none')
  const searchParams = useSearchParams()
  const createQueryString = useQueryStringGenerator()
  const router = useRouter()
  const pathname = usePathname()
  const {isSortable, sortType} = sortProperties || {}

  useEffect(() => {
    if (!isSortable) return

    const sortBy = searchParams.get('sortBy') || ''
    // only allow one column to be sorted at a time
    const [sortColumn, sortOrder] = sortBy.split('_')

    if (!isValidSortOrder(sortOrder) || sortColumn !== columnName) {
      setCurrentSortOrder('none')
      return
    }

    setCurrentSortOrder(sortOrder as SortOrder)
  }, [columnName, isSortable, searchParams])

  const updateSortByParams = (sortOrder: SortOrder) => {
    if (!sortOrder || sortOrder === 'none') {
      const params = new URLSearchParams(searchParams.toString())
      params.delete('sortBy')
      params.delete('page')
      router.push(`${pathname}?${params.toString()}`, {
        scroll: false,
      })
      return
    }

    const sortByValue = `${String(columnName)}_${String(sortOrder)}`
    router.push(createQueryString({page: '', sortBy: sortByValue}), {
      scroll: false,
    })
  }

  if (iconType) {
    return (
      <div className={cn('flex h-full w-full items-center', className)}>
        <HgIcon
          size="small"
          iconType={iconType}
          className="text-icon-default md:hidden"
        />
        <HgIcon
          size="regular"
          iconType={iconType}
          className="hidden text-icon-default md:block lg:hidden"
        />
        <HgIcon
          size="large"
          iconType={iconType}
          className="hidden text-icon-default lg:block"
        />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'relative flex h-full w-full flex-row items-center space-x-s2',
        className
      )}
    >
      <div className="flex flex-col items-start justify-items-center">
        {title && (
          <div className="flex flex-1 items-center text-text-default">
            <span className="arcadia-heading-9">{title}</span>
            {hint && (
              <HgToolTip
                className="relative z-10"
                trigger={TooltipIcon}
                content={hint}
              />
            )}
            {isSortable && sortType && (
              <HgSortButton
                className={sortButtonVariant()}
                handleSort={updateSortByParams}
                sortOrder={currentSortOrder}
                sortType={sortType}
              />
            )}
          </div>
        )}
        {body && (
          <p className="flex flex-1 items-center text-text-default">
            <span className="arcadia-body-2">{body}</span>
            {!title && isSortable && sortType && (
              <HgSortButton
                className={sortButtonVariant()}
                handleSort={updateSortByParams}
                sortOrder={currentSortOrder}
                sortType={sortType}
              />
            )}
          </p>
        )}
      </div>
    </div>
  )
}
