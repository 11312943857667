import * as React from 'react'
import {
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipRoot,
  TooltipTrigger,
} from '~/design-system/foundations/Tooltip'
import {cn} from '~/design-system/utils'

function HgToolTip({
  trigger,
  content,
  className,
}: {
  trigger: React.ReactNode
  content: React.ReactNode
  className?: string
}) {
  return (
    <TooltipProvider delayDuration={100}>
      <TooltipRoot>
        <TooltipTrigger>
          <button
            className={cn(
              'ml-s1 inline-flex h-[16px] w-[16px] cursor-help items-center justify-center rounded-full p-s1 align-[-.0714285705em] text-icon-subdued outline-none transition-colors duration-300 ease-in-out hover:text-icon-default focus-visible:text-icon-default focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-border-focus',
              className
            )}
            aria-label="Information"
          >
            {trigger}
          </button>
        </TooltipTrigger>
        <TooltipContent
          avoidCollisions
          className="text-text-inverted z-40 flex origin-[var(--radix-tooltip-content-transform-origin)] animate-[scaleIn_300ms_ease-out] flex-col items-center rounded-lg bg-surface-inverted px-s3 py-s2 shadow-lg arcadia-body-3"
        >
          <span className="text-text-on-inverted arcadia-body-3">{content}</span>
          <TooltipArrow className="fill-surface-inverted" />
        </TooltipContent>
      </TooltipRoot>
    </TooltipProvider>
  )
}

export default HgToolTip
