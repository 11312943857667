import React from 'react'
import {gridItem} from '~/design-system/foundations'

type HgBlockQuoteProps = {
  quote: React.ReactNode
}

export const HgBlockQuote = ({quote}: HgBlockQuoteProps) => {
  return (
    <blockquote className="border-s border-solid border-border-subdued ps-s4">
      <p className="whitespace-pre-wrap -indent-[0.6ch] tiempos-body-2 before:content-[open-quote] after:content-[close-quote]">
        {quote}
      </p>
    </blockquote>
  )
}

export const HgArticleWYSIWYGBlockQuote = (props: HgBlockQuoteProps) => {
  return (
    <div
      className={gridItem({
        size: 'heading',
        className: 'col-start-2 md:col-span-7 md:col-start-4 lg:col-start-6',
      })}
    >
      <HgBlockQuote {...props} />
    </div>
  )
}
