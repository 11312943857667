import {type ReactNode} from 'react'
import {grid} from '~/design-system/foundations'

const HgArticleWYSIWYGContainer = ({children}: {children: ReactNode}) => {
  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto'})}>{children}</div>
    </div>
  )
}

export default HgArticleWYSIWYGContainer
